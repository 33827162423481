"use client";

import { useRouter } from "next/navigation";
import { motion } from "framer-motion";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { ScrollBar } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";

const websiteId = (process.env.NEXT_PUBLIC_WEBSITE_ID as "nsc" | "jsc") || "nsc";

const NewsFilter = ({ activeFilter, uniqueTags }: { activeFilter: string | string[]; uniqueTags: string[] }) => {
  const router = useRouter();

  const FILTERS = ["Alles", ...uniqueTags];

  return (
    <ScrollArea type="auto" className="w-full overflow-auto">
      <div className="flex max-w-[800px] flex-nowrap gap-x-6 gap-y-4 md:mx-auto md:flex-wrap md:justify-center">
        {FILTERS.map((filter: string) => (
          <div key={filter} className="flex items-center gap-2">
            {filter === activeFilter && (
              <motion.div
                className={cn("h-[11px] w-[11px] rounded-full bg-nsc-mint", websiteId === "jsc" && "bg-jsc-yellow")}
                layoutId="activeFilter"
              />
            )}
            <motion.button
              layout
              onClick={() => router.push(`/nieuws?filter=${filter}`, { scroll: false })}
              style={{
                fontWeight: filter === activeFilter ? "600" : websiteId === "jsc" ? "400" : "300",
              }}
              className={cn("text-nowrap font-roboto text-base font-light", websiteId === "jsc" && "font-lato")}
            >
              {filter}
            </motion.button>
          </div>
        ))}
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
};

export default NewsFilter;
