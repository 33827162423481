import { fetchAPI } from "@/lib/fetch-api";
import { getStrapiContentTypeName, getStrapiURL } from "@/lib/api-helpers";

export async function getDonationByUuid(uuid: string) {
  return await fetchAPI(`${getStrapiContentTypeName("donations")}/${uuid}`);
}

// Client-side function to create a new donation
export async function createDonation(payload: any) {
  return await fetch(`${getStrapiURL(`/api${getStrapiContentTypeName("donations")}`)}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}
