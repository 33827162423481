import { getStrapiContentTypeName, getStrapiURL } from "@/lib/api-helpers";

// Client-side function to create a new submission
export async function createSubmission(rawFormData: any) {
  return await fetch(`${getStrapiURL(`/api${getStrapiContentTypeName("submissions")}`)}`, {
    method: "POST",
    body: JSON.stringify({
      data: rawFormData,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
