"use client";

import React, { useState } from "react";
import { Loader } from "lucide-react";

import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { Button } from "@/components/ui/button";
import { Radio } from "@/components/ui/radio";
import { getStrapiURL } from "@/lib/api-helpers";
import { useReCaptcha } from "next-recaptcha-v3";
import { createDonation } from "@/lib/api/donation";

const DonationForm = ({ defaultValues }: any) => {
  const [selectedAmount, setSelectedAmount] = useState(defaultValues?.donationAmount || "");
  const [formErrors, setFormErrors] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const { executeRecaptcha } = useReCaptcha();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget as HTMLFormElement);
    const errors = validateForm(formData);

    if (errors.length > 0) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    const token = await executeRecaptcha("form_submit");

    const verified = await fetch("/api/verify", {
      method: "POST",
      body: JSON.stringify({
        data: {
          token: token,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!verified.ok) {
      setError(true);
      setLoading(false);
      return;
    }

    // Prepare payload
    const payload = {
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      email: formData.get("email"),
      address: formData.get("address"),
      amount: selectedAmount === "custom" ? Number(formData.get("customAmount")) : Number(selectedAmount),
    };

    try {
      const response = await createDonation(payload);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      // Assuming the API returns a JSON object with a `checkoutUrl`
      setLoading(false);

      window.location.href = result.checkoutUrl; // Redirect user to the checkout URL
    } catch (error) {
      setLoading(false);

      console.error("Error submitting donation:", error);
      // Optionally handle error, show error message to user, etc.
    }
  };

  function validateForm(formData: FormData) {
    const errors = [] as any[];

    if (formData.get("firstName") === "") {
      errors.push({
        id: "firstName",
        message: "Voornaam is verplicht in te vullen",
      });
    }

    if (formData.get("lastName") === "") {
      errors.push({
        id: "lastName",
        message: "Achternaam is verplicht in te vullen",
      });
    }

    if (formData.get("email") === "") {
      errors.push({
        id: "email",
        message: "E-mail is verplicht in te vullen",
      });
    }

    if (formData.get("address") === "") {
      errors.push({
        id: "address",
        message: "Adres is verplicht in te vullen",
      });
    }

    if (formData.get("amount") === "") {
      errors.push({
        id: "amount",
        message: "Bedrag is verplicht in te vullen",
      });
    }

    return errors;
  }

  return (
    <div className="text-white">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <Label htmlFor="firstName">Voornaam*</Label>
          <Input id="firstName" name="firstName" className="text-nsc-dark-blue" required />
          {formErrors.find((error: { id: any }) => error.id === "name") && (
            <p className="text-sm">{formErrors.find((error: { id: any }) => error.id === "name").message}</p>
          )}
        </div>
        <div>
          <Label htmlFor="lastName">Achternaam*</Label>
          <Input id="lastName" name="lastName" className="text-dark-blue" required />
          {formErrors.find((error: { id: any }) => error.id === "lastName") && (
            <p className="text-sm">{formErrors.find((error: { id: any }) => error.id === "lastName").message}</p>
          )}
        </div>
        <div>
          <Label htmlFor="email">E-mailadres*</Label>
          <Input id="email" type="email" name="email" className="text-dark-blue" required />
          {formErrors.find((error: { id: any }) => error.id === "email") && (
            <p className="text-sm">{formErrors.find((error: { id: any }) => error.id === "email").message}</p>
          )}
        </div>
        <div>
          <Label htmlFor="address">Woonplaats*</Label>
          <Input id="address" name="address" className="text-dark-blue" required />
          {formErrors.find((error: { id: any }) => error.id === "address") && (
            <p className="text-sm">{formErrors.find((error: { id: any }) => error.id === "address").message}</p>
          )}
        </div>
        <div>
          <Label>Kies een donatiebedrag</Label>
          <RadioGroup.Root
            name="amount"
            defaultValue={selectedAmount}
            onValueChange={setSelectedAmount}
            className="flex flex-col space-y-2"
            required
          >
            <div className="flex items-center">
              <Radio id="ten" value="10" aria-labelledby="€10" />
              <label htmlFor="ten" className="ml-2">
                €10
              </label>
            </div>

            <div className="flex items-center">
              <Radio id="twenty" value="20" aria-labelledby="€20" />
              <label htmlFor="twenty" className="ml-2">
                €20
              </label>
            </div>

            <div className="flex items-center">
              <Radio id="fifty" value="50" aria-labelledby="€50" />
              <label htmlFor="fifty" className="ml-2">
                €50
              </label>
            </div>

            <div className="flex items-center">
              <Radio id="hundred" value="100" aria-labelledby="€100" />
              <label htmlFor="hundred" className="ml-2">
                €100
              </label>
            </div>

            <div className="flex items-center">
              <Radio id="twohundred" value="200" aria-labelledby="€200" />
              <label htmlFor="twohundred" className="ml-2">
                €200
              </label>
            </div>

            <div className="flex items-center">
              <Radio id="custom" value="custom" aria-labelledby="custom" />
              <label htmlFor="custom" className="ml-2">
                Kies bedrag
              </label>
            </div>
          </RadioGroup.Root>
          {selectedAmount === "custom" && (
            <Input
              type="number"
              id="customAmount"
              name="customAmount"
              placeholder="Voer uw bedrag in (maximaal 850€)"
              required
              className="text-dark-blue mt-2"
              max="4500"
            />
          )}
          {formErrors.find((error: { id: any }) => error.id === "amount") && (
            <p className="text-sm">{formErrors.find((error: { id: any }) => error.id === "amount").message}</p>
          )}
        </div>
        <Button type="submit" className="mt-12 w-full" disabled={loading}>
          {loading ? <Loader className="h-5 w-5 animate-spin" /> : "Plaats donatie"}
        </Button>
      </form>
    </div>
  );
};

export default DonationForm;
